import { useState, useEffect, Fragment } from 'react';
import * as fcl from '@onflow/fcl';
import * as t from '@onflow/types';

// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import {
  FONT_FAMILY,
  FONT_SIZE,
} from 'src/general/components/Typography/Typography';

export const initAcc = `import NonFungibleToken from 0x1d7e57aa55817448
import UFC_NFT from 0x329feb3ab062d289

// This transaction installs the UFC_NFT collection so an
// account can receive UFC_NFT NFTs 

transaction() {
    prepare(signer: AuthAccount) {

        // If the account doesn't already have a collection
        if signer.borrow<&UFC_NFT.Collection>(from: UFC_NFT.CollectionStoragePath) == nil {

            // Create a new empty collection and save it to the account
            signer.save(<-UFC_NFT.createEmptyCollection(), to: UFC_NFT.CollectionStoragePath)

            // Create a public capability to the UFC_NFT collection
            // that exposes the Collection interface
            signer.link<&UFC_NFT.Collection{NonFungibleToken.CollectionPublic,UFC_NFT.UFC_NFTCollectionPublic}>(
                UFC_NFT.CollectionPublicPath,
                target: UFC_NFT.CollectionStoragePath
            )
        }
    }
}`;

const getUFCCollectionScript = `
import UFC_NFT from 0x329feb3ab062d289

pub fun main(acctAddr: Address): Bool {
  let acct = getAccount(acctAddr)
  if let collectionRef = acct.getCapability(UFC_NFT.CollectionPublicPath)!.borrow<&{UFC_NFT.UFC_NFTCollectionPublic}>() {
    return true
  } else {
    return false
  }
}`;

export enum Status {
  WaitingInit = 'THANK YOU! YOUR COLLECTION IS BEING INITIALIZED',
  Redirecting = 'Almost There!',
}

export default function Home() {
  const [navigator, setNavigator] = useState({ userAgent: null });
  useEffect(() => {
    setNavigator(window.navigator);
  }, []);
  const isMobileDevice = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const isChrome = () => navigator.userAgent.includes('Chrome');
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      minHeight="75vh"
      bgImage="url(static/images/auth-bg.png)"
      bgPosition="center"
      bgSize={isMobileDevice() ? 'cover' : '100% 100%'}
      bgRepeat="no-repeat"
    >
      <Flex justifyContent="center">
        <AuthModalForm isMobileDevice={isMobileDevice} isChrome={isChrome} />
      </Flex>
    </Flex>
  );
}

const AuthModalForm = ({ isMobileDevice, isChrome }) => {
  const [state, setStatus] = useState(null);
  const [user, setUser] = useState({ loggedIn: null, addr: null });

  useEffect(() => {
    setStatus(Status.WaitingInit);

    fcl.currentUser.subscribe(setUser);
  }, []);

  useEffect(() => {
    if (user.loggedIn) {
      setStatus(Status.WaitingInit);
      getUFCCollection(user.addr).then(async (isInited) => {
        if (isInited) {
          setStatus(Status.Redirecting);
          redirectToWebFlow(user.addr);
        } else {
          await runInitAcc();
        }
      });
    }
  }, [user]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const redirectToWebFlow = (flowId) => {
    window.location.href = `https://hype.ufcstrike.com/reservation-form?flowId=${flowId}`;
  };

  const getUFCCollection = async (address) => {
    const resp = await fcl.send([
      fcl.script(getUFCCollectionScript),
      fcl.args([fcl.arg(address, t.Address)]),
    ]);
    return fcl.decode(resp);
  };

  const runInitAcc = async () => {
    try {
      await fcl.mutate({
        cadence: initAcc,
        limit: 9999,
      });
      setStatus(Status.Redirecting);
      redirectToWebFlow(user.addr);
    } catch (error) {
      setStatus(Status.WaitingInit);
    }
  };

  return (
    <Box
      bg="#0D0D0F"
      h={user.loggedIn ? '372px' : '500px'}
      w={isMobileDevice() ? '90%' : '640px'}
    >
      <Container
        width="75%"
        padding={
          isMobileDevice && state === Status.WaitingInit
            ? '60px 0px'
            : '80px 0px'
        }
        centerContent
      >
        {user.loggedIn ? (
          <AuthedState
            state={state}
            isMobileDevice={isMobileDevice}
            tryToReinit={runInitAcc}
          />
        ) : (
          <UnauthenticatedState isMobileDevice={isMobileDevice} />
        )}
      </Container>
    </Box>
  );
};

const AuthedState = ({ isMobileDevice, state, tryToReinit }) => {
  return (
    <Fragment>
      <Flex
        flexDirection="column"
        gridGap={state === Status.Redirecting ? '24px' : '5px'}
      >
        <Box textAlign="center" height="100px">
          <Spinner height="75px" width="75px" speed="0.95s" />
        </Box>
        <Flex
          flexDirection="column"
          display="grid"
          gridGap={isMobileDevice() ? '16px' : '24px'}
          textAlign="center"
        >
          <Flex
            fontFamily={FONT_FAMILY.STATE_WIDE_SEMI_BOLD}
            fontSize={FONT_SIZE.bg}
            direction="column"
            lineHeight="24px"
            gridGap={isMobileDevice() ? '16px' : '24px'}
            textTransform="uppercase"
          >
            <Box>{state} </Box>
            <Box>
              {state === Status.WaitingInit && (
                <Button
                  width="100%"
                  height="56px"
                  fontFamily={FONT_FAMILY.STATE_WIDE_SEMI_BOLD}
                  fontSize={FONT_SIZE.mdx}
                  borderRadius="4px"
                  bgColor="#212127"
                  color="white"
                  onClick={tryToReinit}
                >
                  Initialize
                </Button>
              )}
            </Box>
          </Flex>
          {state === Status.WaitingInit ? (
            <Box
              color="#A7AEBB"
              fontFamily={FONT_FAMILY.SHAPIRO_FEATHER}
              fontSize={FONT_SIZE.sm}
              lineHeight="20px"
            >
              Please turn off ad and pop-up blockers for this page or press
              INITIALIZE to complete this step
            </Box>
          ) : (
            <Box
              color="#A7AEBB"
              fontFamily={FONT_FAMILY.SHAPIRO_FEATHER}
              fontSize={FONT_SIZE.sm}
              lineHeight="20px"
            >
              Redirecting you to the UFC Strike Hype NFT reservation form.
            </Box>
          )}
        </Flex>
      </Flex>
    </Fragment>
  );
};

const UnauthenticatedState = ({ isMobileDevice }) => {
  return (
    <Fragment>
      <Flex
        display="grid"
        flexDirection="column"
        gridGap={isMobileDevice() ? '16px' : '24px'}
      >
        <Box
          fontFamily={FONT_FAMILY.STATE_WIDE_SEMI_BOLD}
          fontSize={FONT_SIZE.bg}
          lineHeight="24px"
          textAlign="center"
          textTransform="uppercase"
        >
          <Box>Sign Into your UFC STrike ACCOUNT To</Box>
          <Box>Claim Your Hype NFT</Box>
        </Box>
        <Flex
          flexDirection="column"
          display="grid"
          gridGap="12px"
          width="100%"
          textAlign="center"
        >
          <Box
            fontFamily={FONT_FAMILY.SHAPIRO_FEATHER}
            fontSize={FONT_SIZE.mdx}
            color="#F0F2FD"
          >
            Already have an UFC Strike account?
          </Box>
          <Button
            width="100%"
            height="56px"
            fontFamily={FONT_FAMILY.STATE_WIDE_SEMI_BOLD}
            fontSize={FONT_SIZE.mdx}
            borderRadius="4px"
            bgColor="#212127"
            color="white"
            onClick={fcl.logIn}
          >
            Login
          </Button>
        </Flex>
        <Flex
          flexDirection="column"
          gridGap="12px"
          display="grid"
          width="100%"
          textAlign="center"
        >
          <Box
            fontFamily={FONT_FAMILY.SHAPIRO_FEATHER}
            fontSize={FONT_SIZE.mdx}
            color="#F0F2FD"
          >
            Are you new to UFC Strike?
          </Box>
          <Button
            width="100%"
            height="56px"
            fontFamily={FONT_FAMILY.STATE_WIDE_SEMI_BOLD}
            fontSize={FONT_SIZE.mdx}
            borderRadius="4px"
            onClick={fcl.signUp}
          >
            Signup
          </Button>
        </Flex>
        <Center
          fontFamily={FONT_FAMILY.SHAPIRO_FEATHER}
          fontSize={FONT_SIZE.sm}
          color="#99ABBB"
        >
          Powered by&nbsp;
          <Box fontFamily={FONT_FAMILY.SHAPIRO} color="white">
            Dapper
          </Box>
        </Center>
      </Flex>
    </Fragment>
  );
};
